import { useState } from 'react';
import { AwesomeButton } from "react-awesome-button";

export default function ContactForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log({ name, email, message });
    };

    return (
        <form onSubmit={handleSubmit} className="mx-auto max-w-2xl pt-32 sm:pt-48 lg:pt-56">
            <div className="text-center px-8 md:px-0">
                <h2 className="font-cooper-black-std text-3xl pb-16 tracking-tight text-gray-900 sm:text-4xl">
                    Contact Us
                </h2>
                <div className="relative pb-12">
                    <label
                        htmlFor="name"
                        className="absolute -top-8 left-2 inline-block bg-white px-1 text-xl font-cooper-black-std text-gray-900"
                    >
                        Name
                    </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Jane Smith"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-vivid-cyan sm:text-sm sm:leading-6"
                    />
                </div>
                <div className="relative pb-12">
                    <label
                        htmlFor="email"
                        className="absolute -top-8 left-2 inline-block bg-white px-1 text-xl font-cooper-black-std text-gray-900"
                    >
                        Email
                    </label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="you@example.com"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-vivid-cyan sm:text-sm sm:leading-6"
                    />
                </div>
                <div className="relative pb-12">
                    <label
                        htmlFor="website"
                        className="absolute -top-8 left-2 inline-block bg-white px-1 text-xl font-cooper-black-std text-gray-900"
                    >
                        Website
                    </label>
                    <input
                        type="text"
                        name="website"
                        id="website"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        placeholder="www.example.com"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-vivid-cyan sm:text-sm sm:leading-6"
                    />
                </div>
                <div className="relative pb-12">
                    <label
                        htmlFor="comment"
                        className="absolute -top-8 left-2 inline-block bg-white px-1 text-xl font-cooper-black-std text-gray-900"
                    >
                        Message
                    </label>
                    <textarea
                        rows={4}
                        name="comment"
                        id="comment"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="What do you want to say to us?"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-vivid-cyan sm:text-sm sm:leading-6"
                    />
                </div>
                <AwesomeButton type="primary" ripple href="" target="_self">Submit</AwesomeButton>
            </div>
        </form>
    )
}
