//libraries
import { motion } from 'framer-motion';

const process = [
    {
        name: 'Plan',
        description:
            'Initiate your journey with comprehensive planning that aligns your business objectives with actionable strategies. We ensure a thorough understanding of your vision to set the foundation for success.',
    },
    {
        name: 'Onboard',
        description:
            'A smooth and informative onboarding process designed to align our teams. We focus on clear communication and setting expectations to ensure a seamless integration of efforts.',
    },
    {
        name: 'Collaborate',
        description:
            'Dynamic collaboration that harnesses the strength of combined expertise. Our interactive approach fosters innovation and ensures all stakeholders have a voice in the creative process.',
    },
    {
        name: 'Results',
        description:
            'Achieve tangible outcomes with a focus on delivering high-quality results that meet your objectives. We emphasize measurable success and strategic insights to propel your business forward.',
    },
]


const variants1 = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
};

export default function Process() {

    return (

        <div className="mx-auto max-w-7xl pt-12 pb-20 lg:pt-20">
            <div className="mx-auto grid max-w-3xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
                {process.map((item, index) => (
                    <motion.div
                        key={item.name}
                        variants={variants1}
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: index * 0.5 }}
                    >
                        <time
                            dateTime={item.dateTime}
                            className="flex items-center text-xl font-semibold leading-6 text-vivid-cyan"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="mr-4 h-5 w-4 flex-none">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5 l7 7 m0 0 l-7 7 m7 -7 H3" />
                            </svg>
                            <div className="flex items-center">
                                <svg className="h-px mr-4 w-24 lg:w-16 xl:w-28" xmlns="http://www.w3.org/2000/svg">
                                    <line x1="0" y1="0" x2="100%" y2="0" stroke="currentColor" strokeDasharray="6,6" strokeWidth="2">
                                        <animate attributeName="stroke-dashoffset" from="24" to="0" dur="1s" repeatCount="indefinite" />
                                    </line>
                                </svg>
                            </div>
                            <p className="text-vivid-cyan">{item.name}</p>
                        </time>
                        <p className="mt-1 text-base text-start leading-7 text-gray-600">{item.description}</p>
                    </motion.div>
                ))}
            </div>
        </div >

    )
}