import React, { useState } from 'react';
import { elementVariant } from '../helpers/animationVariants';
import featuredWorksData from '../data/featuredworks.json';

//components
import MonetModal from './MonetModal';
import GetTraindModal from './GetTraindModal';
import DividerWithButton from "./DividerWithButtion";

//libraries
import { motion } from "framer-motion";

const featuredworks = featuredWorksData.map((featuredwork) => ({
  ...featuredwork,
  imageSrc: require(`../media/${featuredwork.imageSrc}`),
}));

export default function FeaturedWorksList() {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [selectedWork, setselectedWork] = useState(null);

  const handleEmail = (e) => {
    e.preventDefault();
    console.log(email);
  };

  return (
    <div className="relative isolate">
      <div className="mx-auto max-w-2xl px-4 pb-8 sm:px-6 sm:pb-12 lg:max-w-7xl">
        <div className="my-6 py-4 sm:mx-auto grid grid-cols-1 gap-y-8 sm:gap-y-10">
          {featuredworks.map((featuredwork) => (
            <motion.div
              key={featuredwork.id}
              className="sm:flex justify-between items-center relative shadow-sm rounded-lg bg-white sm:bg-opacity-75 hover:bg-opacity-100 hover:shadow-lg cursor-pointer"
              initial="offscreen"
              whileInView="onscreen"
              variants={elementVariant}
              viewport={{ once: true, amount: 0 }}
              onClick={(e) => {
                if (['Monet.ai', 'GetTraind'].includes(featuredwork.name)) {
                  e.preventDefault();
                  setselectedWork(featuredwork);
                  setOpen(true);
                }
              }}
            >
              <div className="overflow-hidden rounded-lg sm:w-3/5">
                <img src={featuredwork.imageSrc} alt={featuredwork.imageAlt} className="object-cover object-center" />
              </div>
              <div className="text-gray-600 sm:text-end px-6 py-4">
                <a href={featuredwork.href}></a>
                <h3 className="text-2xl font-cooper-black-std text-vivid-cyan">{featuredwork.name}</h3>
                <p>{featuredwork.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
        <DividerWithButton />
        <MonetModal open={selectedWork?.name === 'Monet.ai'} setOpen={setselectedWork} email={email} setEmail={setEmail} handleEmail={handleEmail} />
        <GetTraindModal open={selectedWork?.name === 'GetTraind'} setOpen={setselectedWork} selectedWork={selectedWork} />
      </div>
    </div>
  )
}
