import Navigation from '../components/Navigation';
import FooterCentered from '../components/FooterCentered';
import ContactForm from '../components/ContactForm';

import { motion } from "framer-motion";

export default function Contact() {

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="bg-white min-h-screen">
                <Navigation />
                <ContactForm />
                <div className="mb-20"></div>
                <FooterCentered />
            </div>
        </motion.div >
    )
}