import { Link } from 'react-router-dom';
import { AwesomeButton } from "react-awesome-button";
import { elementVariant } from '../helpers/animationVariants';

//libraries
import { motion } from "framer-motion";

export default function CTA() {
    return (
        <div className="bg-white">
            <div className="px-6 pb-12 sm:px-6 sm:pb-20 lg:px-8">
                <div className="mx-auto max-w-3xl text-center">
                    <h2 className="font-cooper-black-std text-2xl tracking-tight text-gray-900 sm:text-4xl">
                        Ready to Elevate Your Digital Presence?
                        <br />
                    </h2>
                    <motion.p
                        className="mx-auto mt-6 max-w-2xl text-md sm:text-lg leading-8 text-gray-600"
                        initial="offscreen"
                        whileInView="onscreen"
                        variants={elementVariant}
                        viewport={{ once: true, amount: 0 }}
                    >
                        Let's collaborate to transform your ideas into impactful digital solutions.
                    </motion.p>
                    <motion.div
                        className="mt-10 flex items-center justify-center gap-x-6"
                        initial="offscreen"
                        whileInView="onscreen"
                        variants={elementVariant}
                        viewport={{ once: true, amount: 0 }}
                    >
                        <AwesomeButton type="primary" ripple href="https://outlook.office365.com/book/consultations@bigweblabs.com/" target="_blank">Free Discovery Call</AwesomeButton>
                        <Link to="/contact" className="text-sm font-semibold leading-6 text-gray-900 hover:no-underline hover:text-bright-magenta">
                            Reach Out Today <span aria-hidden="true">→</span>
                        </Link>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}
