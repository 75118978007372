import teamData from '../data/team.json';

//components
import Navigation from '../components/Navigation';
import CTA from '../components/CTA';
import FooterCentered from '../components/FooterCentered';

//libraries
import { motion, useScroll, useTransform } from 'framer-motion';

const timeline = [
    {
        name: 'Strategize',
        description:
            'Dive deep into strategic planning where creativity meets methodology. Our approach involves thoughtful analysis and collaborative brainstorming to ensure every idea is not just viable but visionary.',
        duration: '2-4 Weeks',
    },
    {
        name: 'Design',
        description:
            'Turning vision into detailed plans. We focus on precision & functionality in our designs, ensuring each element is crafted not only for current needs but also for future adaptability.',
        duration: '4-6 Weeks',
    },
    {
        name: 'Build',
        description:
            'We work meticulously to build solutions that embody our commitment to quality & innovation, ensuring every product is robust and ready for real-world challenges.',
        duration: '6-8 Weeks',
    },
    {
        name: 'Deliver',
        description:
            'When and how you need it. We pride ourselves on seamless transitions & meticulous attention to detail, delivering not just a product but a platform for your future growth.',
        duration: 'On Time',
    },
]

const variants1 = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
};

const team = teamData.map((person) => ({
    ...person,
    imageUrl: require(`../media/${person.imageUrl}`),
}));

export default function About() {
    const { scrollYProgress } = useScroll();
    const scale = useTransform(scrollYProgress, [0.30, 0.65], [0, 1]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="bg-white">
                <Navigation />
                <main className="isolate">
                    {/* Hero section */}
                    <div className="relative isolate -z-10 overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
                        <div
                            className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
                            aria-hidden="true"
                        />
                        <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
                            <div className="mx-auto max-w-3xl lg:mx-0 lg:max-w-none lg:gap-x-16 lg:gap-y-6 xl:gap-x-8">
                                <h1 className="max-w-3xl pb-8 text-4xl font-cooper-black-std tracking-tight text-vivid-cyan sm:text-5xl lg:col-span-2 xl:col-auto">
                                    Empowering Innovation, Simplifying Lives
                                </h1>
                                <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                                    <p className="text-lg leading-8 text-gray-600">
                                        In our world, it's all about making connections—between people, ideas, and the next big thing.
                                    </p>
                                </div>
                                <div></div>
                            </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
                    </div>

                    {/* Timeline section */}
                    <div className="mx-auto -mt-8 max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto grid max-w-3xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
                            {timeline.map((item, index) => (
                                <motion.div
                                    key={item.name}
                                    variants={variants1}
                                    initial="hidden"
                                    animate="visible"
                                    transition={{ delay: index * 0.75 }}
                                >
                                    <time
                                        dateTime={item.dateTime}
                                        className="flex items-center text-sm font-semibold leading-6 text-vivid-cyan"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="mr-4 h-5 w-5 flex-none">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5 l7 7 m0 0 l-7 7 m7 -7 H3" />
                                        </svg>
                                        {index !== timeline.length - 1 && (
                                            <div className="flex items-center">
                                                <svg className="h-px mr-4 w-20 sm:w-24 lg:w-32" xmlns="http://www.w3.org/2000/svg">
                                                    <line x1="0" y1="0" x2="100%" y2="0" stroke="currentColor" strokeDasharray="6,6" strokeWidth="2">
                                                        <animate attributeName="stroke-dashoffset" from="24" to="0" dur="1s" repeatCount="indefinite" />
                                                    </line>
                                                </svg>
                                            </div>
                                        )}
                                        <p className="text-vivid-cyan">{item.duration}</p>
                                        {index === timeline.length - 1 && (
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor" className="ml-2 h-5 w-5 flex-none blink">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 2l3.09 6.26L22 9.27l-5 4.73L18.18 22 12 18.27 5.82 22l1.18-7.97L2 9.27l6.91-1.01L12 2z" />
                                            </svg>
                                        )}
                                    </time>
                                    <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{item.name}</p>
                                    <p className="mt-1 text-base leading-7 text-gray-600">{item.description}</p>
                                </motion.div>
                            ))}
                        </div>
                    </div>

                    {/* Team section */}
                    <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
                        <div className="mx-auto max-w-3xl lg:mx-0">
                            <h2 className="text-3xl font-bold tracking-tight font-cooper-black-std sm:text-4xl">The Heartbeat of Big Web Labs</h2>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Driven by passion, curiosity, and lots of coffee, we're building the future of the internet, one project at a time.
                            </p>
                        </div>
                        <ul
                            role="list"
                            className="mx-auto mt-20 grid max-w-3xl grid-cols-2 gap-x-8 gap-y-14 sm:grid-cols-3 lg:mx-0 lg:max-w-none xl:grid-cols-4"
                        >
                            {team.map((person) => (
                                <li key={person.name}>
                                    <img className="aspect-[14/13] w-full rounded-2xl object-cover" src={person.imageUrl} alt="" />
                                    <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                                    <p className="text-base leading-7 text-gray-600">{person.role}</p>
                                    <p className="text-sm leading-6 text-gray-500">{person.location}</p>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Logo CLoud */}
                    {/* <div className="bg-white pt-24">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
                                Trusted by the world’s most innovative teams
                            </h2>
                            <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                                <img
                                    className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                                    src="https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg"
                                    alt="Transistor"
                                    width={158}
                                    height={48}
                                />
                                <img
                                    className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                                    src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg"
                                    alt="Reform"
                                    width={158}
                                    height={48}
                                />
                                <img
                                    className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                                    src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg"
                                    alt="Tuple"
                                    width={158}
                                    height={48}
                                />
                                <img
                                    className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                                    src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg"
                                    alt="SavvyCal"
                                    width={158}
                                    height={48}
                                />
                                <img
                                    className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                                    src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg"
                                    alt="Statamic"
                                    width={158}
                                    height={48}
                                />
                            </div>
                        </div>
                    </div> */}

                    {/* Stats */}
                    <div className="mx-auto my-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
                        <div className="mx-auto max-w-3xl lg:mx-0">
                            <h2 className="text-3xl font-bold tracking-tight font-cooper-black-std sm:text-4xl">
                                By the Numbers: Our Journey So Far
                            </h2>
                            <p className="mt-6 text-base leading-7 text-gray-600">
                                A glimpse into the milestones that define us: groundbreaking projects, happy clients, and the endless pursuit of digital excellence.
                            </p>
                        </div>
                        <div className="mx-auto mt-16 flex max-w-3xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
                            <motion.div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-900 p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44" style={{ scaleY: scale }}>
                                <p className="flex-none text-3xl font-bold tracking-tight text-white">100%</p>
                                <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                                    <p className="text-lg font-semibold tracking-tight text-white">
                                        Client Satisfaction Rate
                                    </p>
                                    <p className="mt-2 text-base leading-7 text-gray-400">
                                        Embracing every mistake and piece of feedback as a stepping stone, ensuring the work doesn't stop till our clients are happy.
                                    </p>
                                </div>
                            </motion.div>
                            <motion.div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-indigo-600 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28" style={{ scaleY: scale }}>
                                <p className="flex-none text-3xl font-bold tracking-tight text-white">38</p>
                                <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                                    <p className="text-lg font-semibold tracking-tight text-white">We’re proud to have delivered 38 projects to date and counting</p>
                                    <p className="mt-2 text-base leading-7 text-indigo-200">From dynamic websites to custom AI solutions, each project is a testament to our commitment to innovation and client satisfaction.
                                    </p>
                                </div>
                            </motion.div>
                            <motion.div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start" style={{ scaleY: scale }}>
                                <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">50%+</p>
                                <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                                    <p className="text-lg font-semibold tracking-tight text-gray-900">Average Growth in Client Base</p>
                                    <p className="mt-2 text-base leading-7 text-gray-600">
                                        Demonstrating our expanding reach and the trust businesses place in us to navigate the digital landscape together.
                                    </p>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </main>
                <div className="my-40"></div>
                <CTA />
                <FooterCentered />
            </div>
        </motion.div>
    )
}
