import { useState } from 'react';
import workData from '../data/works.json';

//components
import '../global.css';
import MonetModal from '../components/MonetModal';
import GetTraindModal from '../components/GetTraindModal';
import Navigation from '../components/Navigation';
import FooterCentered from '../components/FooterCentered';

//libraries
import { motion } from "framer-motion";

const works = workData.map((work) => ({
    ...work,
    imageSrc: require(`../media/${work.imageSrc}`),
}));

export default function Works() {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [selectedWork, setselectedWork] = useState(null);

    const handleEmail = (e) => {
        e.preventDefault();
        console.log(email);
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="bg-white">
                <Navigation />
                {/* <div className="mx-auto max-w-2xl pt-32 sm:pt-48 lg:pt-56">
                    <div className="text-center">
                        <h2 className="font-cooper-black-std text-3xl tracking-tight text-gray-900 sm:text-4xl">
                            Clients
                        </h2>
                    </div>
                </div> */}
                <div className="mx-auto min-w-full px-8 py-16 sm:px-6 sm:py-24">
                    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-5">
                        {works.map((work) => (
                            <div
                                key={work.id}
                                className="group relative flex flex-col overflow-hidden bg-white transition duration-500 ease-in-out transform hover:-translate-y-1"
                                onClick={() => {
                                    setselectedWork(work);
                                    setOpen(true);
                                }}
                            >
                                <div className="aspect-h-4 aspect-w-3 sm:aspect-none group-hover:opacity-75 sm:h-auto">
                                    <a href={work.href}>
                                        <img
                                            src={work.imageSrc}
                                            alt={work.imageAlt}
                                            className="p-1 h-full w-full mx-auto sm:h-full sm:w-full"
                                        />
                                    </a>
                                </div>
                                {/* <div className="flex flex-1 flex-col space-y-2 text-center bg-gray-100">
                                    <h3 className="font-cooper-black-std text-md font-medium text-gray-900 pt-6">
                                        <a href={work.href}>
                                            <span aria-hidden="true" className="absolute inset-0" />
                                            {work.name}
                                        </a>
                                    </h3>
                                    <p className="hidden md:flex text-sm text-gray-500 pt-4 px-4">{work.description}</p>
                                    <div className="flex flex-1 flex-col justify-end">
                                        <p className="text-xs sm:text-sm italic text-gray-500 px-4 pb-6">{work.options}</p>
                                    </div>
                                </div> */}
                            </div>
                        ))}
                    </div>
                </div>

                <MonetModal open={selectedWork?.name === 'Monet.ai'} setOpen={setselectedWork} email={email} setEmail={setEmail} handleEmail={handleEmail} />
                <GetTraindModal open={open && selectedWork && selectedWork.name === 'GetTraind'} setOpen={setOpen} selectedWork={selectedWork} />
                <div className="my-40"></div>
                <FooterCentered />
            </div>
        </motion.div>
    )
}
